<template>
    <div class="banner-box">
        <div class="banner-img"></div>
        <div class="banner-nav">
            <div class="banner-nav-content">                
                <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
                    <span><i class="el-icon-location"></i>当前位置：</span>
                    <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: navUrl }"  :title="navName">{{navName}}</el-breadcrumb-item>    
                </el-breadcrumb> -->
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <span><i class="el-icon-location"></i>当前位置：</span>
                    <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
                    <el-breadcrumb-item v-for="item in this.getBreadList()"
                    :to="item.path" :key="item.path">{{item.title}}</el-breadcrumb-item>    
                </el-breadcrumb>
            </div>            
        </div>
    </div>
</template>

<script>
export default { 
	name: 'banner',
	data () {
		return{}
	},
	props: {
        navName:{
            type: String,
            required: true
        },
        navUrl:{
            type: String,
            required: true
        },
	},
    mounted(){console.log(this.$route)},
	methods:{
        getBreadList(){
            let currentPath = this.$route.path;
            let routes = this.$router.options.routes;
            return this.breadListSearch(routes,currentPath);
            console.log(routes,"routes")
        },
        breadListSearch(routes,currentPath,breadList){
            breadList=breadList || [];
            breadList=[...breadList];
            for(let i = routes.length - 1; i >= 0; i--){
                if(routes[i].path === currentPath){
                    if(routes[i].meta && routes[i].meta.title){
                        breadList.push({"path":routes[i].path,"title":routes[i].meta.title});
                        return breadList;
                    }else{
                        if(routes[i].children && currentPath.indexOf(routes[i].path) === 0){
                            breadList.push({"path":routes[i].path,"title":routes[i].meta.title});
                            return this.breadListSearch(routes[i].children,currentPath.breadList,);
                        }
                    }
                }
            }
        }
	},
    // computed:{
    //     list(){
    //         return this.$route.matched
    //     }
    // }
}
</script>

<style scoped lang="less">
@media screen and (min-width:750px){
    .banner-box{
        .banner-img{
            height: 280Px;
            background: url(../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
        }
        .banner-nav{
            background-color: #f6f8fb;
            .banner-nav-content{
                width: 1200Px;
                margin: auto;
                /deep/ .el-breadcrumb{
                    line-height: 70Px;
                }
                /deep/.el-breadcrumb__inner.is-link{
                    font-weight: unset;
                    color: #333;
                    cursor: pointer;
                    &:hover{
                        color: #093C6C;
                        text-decoration: underline;
                    }
                }
                span{
                    display: flex;
                    align-items: center;
                    .el-icon-location{
                        color: #093C6C;
                        font-size: 20Px;
                        margin-right: 12Px;
                    }
                    float: left;
                    color:#5a5a5a;
                }
            }
        }
    }
}

@media screen and (max-width:750px){
    .banner-box{
        .banner-img{
            height: 110px;
            background: url(../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
        }
        .banner-nav{
            background-color: #f6f8fb;
            .banner-nav-content{
                width: 95%;
                margin: auto;
                /deep/ .el-breadcrumb{
                    line-height: 35px;
                }
                /deep/.el-breadcrumb__inner.is-link{
                    font-weight: unset;
                    color: #333;
                    cursor: pointer;
                    &:hover{
                        color: #093C6C;
                        text-decoration: underline;
                    }
                }
                span{
                    display: flex;
                    align-items: center;
                    .el-icon-location{
                        color: #093C6C;
                        font-size: 19px;
                        margin-right: 2px;
                    }
                    float: left;
                    color:#5a5a5a;
                }
            }
        }
    }
}
</style>